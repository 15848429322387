<template>
  <div class="yjy_news_detail_page">
    <div class="yjy_news_detail_body">
      <div class="yjy_news_detail_page_left">
        <div class="yjy_news_detail_bread">
          <div class="ynd_bread_title" @click="goBack()">新闻资讯></div>
          <div class="ynd_bread_title" @click="goBack()">{{ newType }}></div>
          <div class="ynd_bread_title2">{{ newsDetail.title }}</div>
        </div>
        <div class="yjy_news_detail_page_top">
          <div class="yjy_news_detail_page_top_title">
            {{ newsDetail.title }}
          </div>
          <div class="yjy_news_detail_page_top_time">
            <img :src="yjyNewsClock" alt="" class="clock_image" />
            <p>{{ formatDate(newsDetail.releaseTime) }}</p>
          </div>
        </div>
        <div
          class="yjy_news_detail_page_body"
          v-html="newsDetail.content"
        ></div>

        <div class="yjy_news_next_title" @click="getNextNewsContent">
          <p class="next_news_title">{{"下一篇："+ nextNewsTitle }}</p>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";

export default {
  components: {},

  data() {
    return {
      newsId: null,
      hotList: [],
      newsDetail: {}, // 展示的新闻
      newType: "公司动态",
      yjyNewsClock: require("@/assets/yjysite/news/yjy_news_clock.png"),
      companyList: {},
      industryList: {},
      nextNewsId: null,
      nextNewsTitle: "",
    };
  },

  created() {
    debugger
    console.log("---------->");
  },

  mounted() {
    debugger
    let that = this;
    if (this.$route.query && this.$route.query.id != null) {
      this.newsId = this.$route.query.id;
    }

    that.getYgxNewsDetail();
    that.getNewsList();
    
  },

  methods: {

    goBack(){
      console.log("goBack------------>");
      // this.$router.push({ path: '${this.$route.path}/yjyLatestNews' });
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/yjysite.html#/yjyLatestNews"
        window.close();
        window.open(url);
    },

    formatDate(dateTime) {
      // 使用字符串分割获取日期部分
      const datePart = dateTime.split(" ")[0];
      return datePart;
    },

    //公司动态

    getIDAndTitle() {
      this.companyList.forEach((item, index) => {
        if (this.newsId == item.id) {
          this.nextNewsId = this.companyList[index + 1].id;
          this.nextNewsTitle = this.companyList[index + 1].title;
        }
      });
    },

    getNextNewsContent(){
      this.newsDetail = this.companyList;
      this.queryNextNews();
      window.scrollTo(0, 0);
    },

    queryNextNews() {
      let that = this;
      let time = new Date()
      let params={
            start: 0,
            limit: 1,
             q:{ 
              
                 dictCode: this.newType == "公司动态" ? "'NEWS_TYPE_MS_COMPANY'" : "'NEWS_TYPE_MS_INDUSTRY'",
                 time: this.newsDetail.releaseTime,
                 fields:[
                     "id",
                     "title",
                     "intro",
                     "category",
                     "categoryId",
                     "coverImg",
                     "releaseTime",
                     "status",
                     "content"
                 ],
                //  sort:"id__desc",
                 
           }

      }
      aEntityAPI
        .request("NewsService", "getNextNewsId", {qp: JSON.stringify(params)})
        .then((res) => {
          if (res.data.success) {
            let data = res.data.data;
            this.companyList = data[0];
            this.nextNewsTitle = data[0].title;
            this.nextNewsId = data[0].id;
          }
        });
    },

    //获取云共享企业新闻列表
    getYgxNewsDetail() {
      let that = this;

      aEntityAPI
        .request("NewsService", "getYgxNewsDetail", { id: that.newsId })
        .then((response) => {
          this.grid_loading = false;
          if (response.data.success) {
            that.newsDetail = response.data.data;
            debugger;
            if (
              that.newsDetail != null &&
              that.newsDetail.category != null &&
              that.newsDetail.category.code == "NEWS_TYPE_MS_COMPANY"
            ) {
              this.newType = "公司动态";
            } else {
              this.newType = "行业动态";
            }
            that.queryNextNews();
          } else {
            this.$message.error("查询新闻出错");
          }
        });
    },

    getNewsList() {
      let that = this;
      let params = {
        start: 0,
        limit: 5,
        q: {
          dictCode: "'NEWS_TYPE_INDUSTRY','NEWS_TYPE_YJY'",
          fields: [
            "id",
            "title",
            "intro",
            "category",
            "categoryId",
            "coverImg",
            "releaseTime",
            "status",
            "content",
          ],
          sort: "release_time__asc",
        },
      };

      aEntityAPI
        .request("NewsService", "getYgxNewsList", {
          qp: JSON.stringify(params),
        })
        .then((response) => {
          that.grid_loading = false;
          if (response.data.success) {
            that.moreTag = true;
            var dataList = response.data.data;
            if (dataList.length > 0) {
              that.hotList = dataList;
            }
          } else {
            that.$message.error("查询新闻出错");
          }
        });
    },
  },
};
</script>


<style>
.yjy_news_detail_page {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
}

.yjy_news_detail_body {
  width: 1200px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-top: 32px;
}

.yjy_news_detail_page_left {
  display: flex;
  flex: 2;
  background-color: #fff;
  flex-direction: column;
}

.yjy_news_detail_bread {
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  margin: 40px 0;
}

.ynd_bread_title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  cursor: pointer; /* 鼠标指针样式 */
}

.ynd_bread_title2 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  /* font-weight: 500; */
  color: #333333;
  font-weight: bold;
}

.yjy_news_detail_page_top {
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-around;
  margin: 20px 0;
  padding-bottom: 21px;
  /* border-bottom: 1px solid #D8D8D8; */
}

.yjy_news_detail_page_top_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 36px;
  color: #333333;
  line-height: 36px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.yjy_news_detail_page_top_time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  justify-content: center;
  color: #999999;
  line-height: 20px;
  margin-top: 22px;
}

.yjy_news_detail_page_body {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  flex: 2;
  width: 100%;
  min-height: 50vh;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 16px;
  color: #575d6c;
  text-align: left;
  font-style: normal;
  text-transform: none;
  border-bottom: 1px solid #d8d8d8;
  line-height: 30px;
}

.ynd_hot {
  width: 240px;
  margin-top: 127px;
  margin-left: 44px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #d8d8d8;
}

.ynd_hot_title {
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  width: 80%;
  text-align: center;
  padding-bottom: 31px;
  margin-bottom: 29px;
  border-bottom: 1px dashed #d8d8d8;
}

.ynd_hot_body {
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
}

.ynd_hot_body_item {
  width: 100%;
  display: flex;
  flex-direction: row;

  cursor: pointer;
  margin: 10px 0;
}

.ynd_hot_body_item:hover .ynd_hot_body_item_point_body {
  background: #4b6fc8;
}

.ynd_hot_body_item:hover .ynd_hot_body_item_msg {
  color: #4b6fc8;
}

.ynd_hot_body_item:hover .ynd_hot_body_item_time {
  color: #4b6fc8;
}

.ynd_hot_body_item_point_body {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.ynd_hot_body_item_point_body {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 3px;
  margin-right: 20px;
}

.ynd_hot_body_item_info {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ynd_hot_body_item_msg {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 5px;

  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ynd_hot_body_item_time {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

.news_image {
  width: 690px;
  height: 430px;
}

.clock_image {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.next_news_title {
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.yjy_news_next_title{
  cursor: pointer; /* 鼠标指针样式 */
}

.yjy_news_next_title:hover .next_news_title{
    color: #0055FF; /* 当鼠标悬停时，标题字体颜色变为蓝色 */
}
</style>