<!-- 供应链控制塔 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="sceneImg" class="solu_single-img body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="img_wrap">
					<img :src="sceneImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="architectureImg" :ref="menuList[1]" class="solu_single-img body_suit item white_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="img_wrap">
					<img :src="architectureImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="values.length > 0" :ref="menuList[2]" class="solu_values body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案价值</p>
				</div>
				<div class="values_wrap">
					<div class="value" v-for="(value, index) in values" :key="index">
						<p class="p1">{{ value.title }}</p>
						<p>{{ value.subTitle }}</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="advantages.length > 0" :ref="menuList[3]" class="solu_advantages body_suit item white_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>方案优势</p>
				</div>

				<div class="advantages_wrap">

					<div class="advantage" v-for="(advantage, index) in advantages" :key="index"
						@mouseenter="advantage.active = true" @mouseleave="advantage.active = false">
						<img class="bg_img" :src="__imagePath + advantage.imgurl" alt="" />
						<div class="li_wrap">
							<div class="li_title">
								<p>{{ advantage.title }}</p>
								<div class="li_title_line" />
							</div>
							<ul class="li_subtitle" :class="{ 'li_subtitle_active': advantage.active }">
								<li v-for="(sub, sIndex) in advantage.subtitles" :class="{ 'li_zero': sIndex == 0 }">
									<p>{{ sub }}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div v-if="modes.length > 0" :ref="menuList[4]" class="solu_modes body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>建模仿真</p>
				</div>
				<div class="modes_wrap">
					<div class="mode_wrap" v-for="(mode, index) in modes" :key="index">
						<div class="item_title">
							<img :src="__imagePath + mode.imageurl" alt="" />
							<p>{{ mode.title }}</p>
						</div>
						<ul>
							<li v-for="(sub, sIndex) in mode.subTitles" :key="sIndex">
								<p>{{ '• ' + sub }}</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div v-if="cases.length > 0" :ref="menuList[5]" class="solu_cases body_suit item white_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>精选客户案例</p>
				</div>

				<solutionCasesView column="2" column-margin="24px" carousel-top="50px" :model="cases"
					:carousel-height="caseItemHeight + 'px'">
					<template v-slot="{ item }">
						<div class="case_wrap">
							<img :src="item.subImageUrl" alt="" />
							<div class="case_title">
								<p class="p1">{{ item.title  }}</p>
								<p>{{ item.subTitle }}</p>
							</div>
						</div>
					</template>
				</solutionCasesView>

			</div>
		</div>

		<div :ref="menuList[6]" class="solu_download body_suit item dray_background">
			<solutionDataView type="gylkzt" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/gylkztSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '供应链控制塔',
					subTitle: '供应链管理总引擎，实现产供销、运仓配和部门间高效协同'
				},
				menuList: ['应用场景', '架构图', '方案价值', '方案优势', '建模仿真', '精选案例', '相关资料'],
				sceneImg: null,
				architectureImg: null,

				values: [],
				advantages: [],
				modes: [],

				caseItemHeight: 374,
				cases: []

			};
		},

		created() {},
		mounted() {
			window.addEventListener("resize", this.windowChange, false);

			this.loadDatas()
		},

		destroyed() {
			window.removeEventListener("resize", this.windowChange, false);
		},

		methods: {
			windowChange() {
				const case_wrap = this.$refs[`${ '精选案例' }`]
				if (case_wrap) {
					this.$nextTick(() => {
						let rect = case_wrap.getBoundingClientRect();
						this.caseItemHeight = rect.width * 374 / 1920
					})
				}
			},

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header;
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl;
					}
					that.header = header;
				}

				let sceneBg = page.sceneBg;
				if (sceneBg) {
					that.sceneImg = that.__imagePath + sceneBg
				}
				let archBgImg = page.architectureBg;
				if (archBgImg) {
					that.architectureImg = that.__imagePath + archBgImg
				}
				let values = page.values;
				if (values && values.length > 0) {
					that.values = values
				}
				let modes = page.modes;
				if (modes && modes.length > 0) {
					that.modes = modes
				}
				let advantages = page.advantages;
				if (advantages && advantages.length > 0) {
					advantages.forEach(item => {
						item.active = false
					})
					that.advantages = advantages
				}

			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 93
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}

						let cases = response.data.data.cases;
						if (cases && cases.length > 0) {
							that.$set(that, 'cases', cases)
						}

					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background-color: #f4f8fb;
	}

	.white_background {
		background-color: #FFFFFF;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 单图 */
	.solu_single-img {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.img_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 方案价值 */
	.solu_values {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.values_wrap {
			margin-top: 65px;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 38px 20px;

			.value {

				border-radius: 10px;
				border: 1px solid #DFE1E6;
				background-color: #FFFFFF;

				padding: 24px 50px;

				display: flex;
				flex-direction: column;

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;

					margin-bottom: 10px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}

			}

			.value:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 方案优势 */
	.solu_advantages {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.advantages_wrap {

			margin-top: 65px;
			margin-top: 4.0625rem;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 26px 21px;

			.no_margintop {
				margin-top: 0;
			}

			.no_marginleft {
				margin-left: 0;
			}

			.advantage {
				width: 100%;
				aspect-ratio: 1.779;
				margin: 0 auto;
				scale: 1;

				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: center;

				display: flex;
				position: relative;

				.bg_img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;

					z-index: 0;

					transition: all 0.3s ease;
				}

				.li_wrap {
					padding: 10%;
					z-index: 21;

					display: flex;
					flex-direction: column;

					.li_title {
						margin-bottom: 6.4%;

						display: flex;
						flex-direction: column;

						.li_title_line {
							margin-top: 3%;

							width: 7%;
							height: 3px;

							background-color: #FFFFFF;
						}

						p {
							font-family: HanSans-Blod;
							font-weight: 700;
							font-size: 20px;
							color: #FFFFFF;
						}
					}

					.li_subtitle {

						opacity: 0;

						transition: all 0.3s ease;

						li {
							margin-top: 3.2%;
						}

						.li_zero {
							margin-top: 0;
						}

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #FFFFFF;
						}
					}

					.li_subtitle_active {
						opacity: 1;
					}
				}
			}

			.advantage:hover {
				scale: 1.03;
			}
		}
	}

	/* 建模仿真 */
	.solu_modes {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;


		.modes_wrap {

			margin-top: 65px;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 24px;

			.mode_wrap {
				background-color: #FFFFFF;
				border-radius: 4px;

				padding: 20px 40px;

				display: flex;
				flex-direction: column;
				justify-content: center;

				.item_title {
					display: flex;
					flex-direction: row;
					align-items: center;

					p {
						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 20px;
						color: #333333;
					}

					img {
						width: 24px;
						height: 24px;

						object-fit: contain;

						margin-right: 20px;
					}

					margin-bottom: 20px;
				}

				ul {
					// margin: 0 20px;

					display: flex;
					flex-wrap: wrap;

					li {
						flex-basis: 33.33%;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #666666;
						}
					}
				}

			}

			.mode_wrap:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 精选客户案例 */
	.solu_cases {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.case_wrap {

			height: 100%;

			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				aspect-ratio: 3.77;
				object-fit: contain;
				background-color: rgba(0, 0, 0, 0.1);
			}

			.case_title {

				padding: 4.2%;
				padding-bottom: 0;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #788296;

					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 18px;
					color: #353C58;

					-webkit-line-clamp: 1;

					margin-bottom: 2.8%;
				}
			}
		}

	}

	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>