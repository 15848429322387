<!-- 城市物流资源整合平台 -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="sceneImg" class="solu_single_image body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="img_wrap scene">
					<img :src="sceneImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="architectureImg" :ref="menuList[1]" class="solu_single_image body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>平台架构图</p>
				</div>
				<div class="img_wrap">
					<img :src="architectureImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="wlzycsImg" :ref="menuList[2]" class="solu_single_image body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>物流资源超市</p>
				</div>
				<div class="img_wrap wlzycs">
					<img :src="wlzycsImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="wlfacsImg" :ref="menuList[3]" class="solu_single_image body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>物流解决方案超市</p>
				</div>
				<div class="img_wrap wlfacs">
					<img :src="wlfacsImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="trends.length > 0" :ref="menuList[4]" class="solu_trends body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>行业趋势</p>
				</div>
				<div class="trends_wrap">
					<div class="trends" v-for="(item, index) in trends" :key="index">
						<div class="trends_key">
							<p>{{ item.key }}</p>
						</div>
						<p class="p_title">{{ item.title }}</p>
						<ul>
							<li v-for="(sub, sIndex) in item.subTitles" :key="sIndex">
								<p>{{ '• ' + sub }}</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div :ref="menuList[6]" class="solu_download body_suit item dray_background">
			<solutionDataView type="cswlpt" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/cswlptSolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '城市物流资源整合平台'
				},
				menuList: ['应用场景', '架构图', '物流资源超市', '物流解决方案超市', '行业趋势', '相关资料'],
				sceneImg: null,
				architectureImg: null,
				wlzycsImg: null, // 物流资源超市
				wlfacsImg: null, // 物流方案超市

				trends: []

			};
		},

		created() {},
		mounted() {
			this.loadDatas()
		},

		methods: {

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let sceneBg = page.sceneImg;
				if (sceneBg) {
					that.sceneImg = that.__imagePath + sceneBg
				}
				let architectureImg = page.architectureImg;
				if (architectureImg) {
					that.architectureImg = that.__imagePath + architectureImg
				}
				let wlzycsImg = page.wlzycsImg;
				if (wlzycsImg) {
					that.wlzycsImg = that.__imagePath + wlzycsImg
				}
				let wlfacsImg = page.wlfacsImg;
				if (wlfacsImg) {
					that.wlfacsImg = that.__imagePath + wlfacsImg
				}
				let trends = page.trends;
				if (trends && trends.length > 0) {
					that.trends = trends
				}

			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 89
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 应用场景 */
	.solu_single_image {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.img_wrap {
			margin-top: 50px;

			img {
				width: 100%;
			}
		}

		.scene {
			padding: 0 4.82%;
		}

		/* 物流资源超市 */
		.wlzycs {
			padding: 0 4.375%;
		}

		/* 物流解决方案超市 */
		.wlfacs {
			padding: 0 4.62%;
		}
	}



	/* 行业趋势 */
	.solu_trends {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.trends_wrap {

			margin-top: 65px;

			display: grid;
			flex-direction: column;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 0 21px;

			.trends {

				padding: 0 8.58%;

				background-color: #F5F5F5;
				border-radius: 10px;
				aspect-ratio: 2.24;

				display: flex;
				flex-direction: column;
				align-items: center;

				position: relative;

				.trends_key {
					height: 27%;
					position: absolute;

					background: #FFFFFF;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
					border-radius: 10px;

					padding: 0 9.66%;

					top: -14%;

					display: flex;
					align-items: center;

					p {

						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 20px;
						color: #0055FF;
					}
				}

				.p_title {
					margin-top: 15%;

					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;
				}

				ul {
					margin-top: 4.29%;
					margin-bottom: 6.44%;

					li {
						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #666666;
							text-align: center;
						}
					}
				}
			}

			.trends:hover {
				box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
			}
		}

	}


	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>