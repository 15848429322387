<!-- 多式联运解决方案 -->
<template>
	<div class="solution_body">
		<div class="dsly_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="archBgImg" class="dsly_architecture body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="arch_wrap">
					<img :src="archBgImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="planBgImg" :ref="menuList[1]" class="dsly_plans body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>多段运输、方案规划</p>
				</div>
				<div class="plan_wrap">
					<img :src="planBgImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="mechanics.length > 0" :ref="menuList[2]" class="dsly_mechanics body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>平台人工智能</p>
				</div>
				<div ref="mechWrap" class="mech_wrap" :style="mechStyle">
					<div class="mech_item" v-for="(mech, index) in mechanics" :key="index"
						@mouseenter="onMechMouseenter(mech)" @mouseleave="onMechMouseleave(mech)">
						<div class="mech_item_wrap">
							<img :src="__imagePath + (mech.active ? mech.imageurlA : mech.imageurl)"
								:class="{ img_active: mech.active }" />
							<div class="p_title" :class="{ p_active: mech.active }">
								<p>{{ mech.title }}</p>
							</div>

							<ul v-show="mech.active">
								<li v-for="(sub, sIndex) in mech.subtitles" :key="sIndex">
									<p>{{ sub }}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="cases.length > 0" :ref="menuList[3]" class="dsly_cases body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>精选客户案例</p>
				</div>
				<solutionCasesView column="3" column-margin="30px" carousel-top="50px" :model="cases"
					:carousel-height="caseItemHeight + 'px'">
					<template v-slot="{ item }">
						<div class="case_wrap">
							<img :src="item.subImageUrl" alt="" />
							<div class="case_title">
								<p class="p1">{{ item.title  }}</p>
								<p>{{ item.subTitle }}</p>
							</div>
						</div>
					</template>
				</solutionCasesView>
			</div>
		</div>

		<div :ref="menuList[4]" class="dsly_download body_suit item dray_background">
			<solutionDataView type="dsly" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>
	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue";
	import solutionDataView from "./commonView/solutionDataView.vue";
	import solutionCasesView from "./commonView/solutionCasesView.vue";
	import localPage from "../json/dslySolution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView,
		},
		data() {
			return {
				header: {
					title: "多式联运解决方案",
					subTitle: "",
				},
				menuList: ["架构图", "方案规划", "人工智能", "客户案例", "相关资料"],
				archBgImg: null,
				planBgImg: null,

				mechanics: [],
				mechStyle: {
					gridTemplateColumns: "repeat(5, 1fr)",
				},

				caseItemHeight: 374,
				cases: [],
			};
		},

		created() {},
		mounted() {
			window.addEventListener("resize", this.windowChange, false);

			this.loadDatas();
		},

		destroyed() {
			window.removeEventListener("resize", this.windowChange, false);
		},

		methods: {
			windowChange() {
				const case_wrap = this.$refs[`${"客户案例"}`];
				if (case_wrap) {
					this.$nextTick(() => {
						let rect = case_wrap.getBoundingClientRect();
						this.caseItemHeight = (rect.width * 374) / 1920;
					});
				}
			},

			scrollToEL(e) {
				console.log("scrollToEL");
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${e}`];
					console.log("element:" + element);
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				});
			},

			onMechMouseenter(mech) {
				mech.active = true;
				this.mechWrapStyle(true);
			},
			onMechMouseleave(mech) {
				mech.active = false;
				this.mechWrapStyle(false);
			},

			mechWrapStyle(enter) {
				let count = this.mechanics.length
				if (enter) {
					const el = this.$refs.mechWrap;
					if (!el) return;
					let rect = el.getBoundingClientRect();
					let rectWidth = rect.width * window.devicePixelRatio
					let average = (rectWidth - (count - 1)) / count;
					let big = average * 1.2;
					let small = (rectWidth - big) / (count - 1);

					let gridC = "";
					this.mechanics.forEach((mech, index) => {
						if (mech.active == true) {
							if (index == 0) gridC += big + "px";
							else gridC += " " + big + "px";
						} else {
							if (index == 0) gridC += small + "px";
							else gridC += " " + small + "px";
						}
					});
					this.mechStyle = {
						gridTemplateColumns: gridC,
					};
				} else {
					this.mechStyle = {
						gridTemplateColumns: 'repeat(' + count + ', 1fr)',
					};
				}
			},

			headerBtnClick(e) {
				console.log("headerBtnClick");
			},
			headerMenuClick(e) {
				console.log("headerMenuClick:" + e);
				this.scrollToEL(e);
			},
			onDownload() {},
			onVideo() {},

			handlePage(page) {
				let that = this;

				let header = page.header;
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = this.__imagePath + header.bgUrl;
					}
					that.header = header;
				}

				let archBgImg = page.archBgImg;
				if (archBgImg) {
					that.archBgImg = that.__imagePath + archBgImg
				}
				let planBgImg = page.planBgImg;
				if (planBgImg) {
					that.planBgImg = that.__imagePath + planBgImg
				}
				let mechanics = page.mechanics;
				if (mechanics && mechanics.length > 0) {
					that.mechanics = mechanics
					this.mechStyle = {
						gridTemplateColumns: 'repeat(' + mechanics.length + ', 1fr)',
					};
				}
			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 82,
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}
						let cases = response.data.data.cases;
						if (cases && cases.length > 0) {
							that.$set(that, "cases", cases);
						}
					}
				});
			}
		},
	};
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.dsly_header {
		display: flex;
		flex-direction: column;
	}

	/* 模块功能 */
	.dsly_function {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.function_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			img {
				width: 100%;
			}
		}
	}

	/* 架构图 */
	.dsly_architecture {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.arch_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			img {
				width: 100%;
			}
		}
	}

	/* 方案规划 */
	.dsly_plans {
		display: flex;
		flex-direction: column;

		background-color: #ffffff;
		padding: 70px 0;
		padding: 4.375rem 0;

		.plan_wrap {
			margin-top: 48px;
			margin-top: 3rem;
			padding: 40px 50px;

			background: #f5f5f5;
			border-radius: 14px;

			img {
				width: 100%;
			}
		}
	}

	/* 安全机制 */
	.dsly_mechanics {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.mech_wrap {
			margin-top: 48px;
			margin-top: 3rem;

			aspect-ratio: 3.81;

			display: grid;
			flex-direction: row;
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 0 1px;

			transition: all 0.25s ease;

			.mech_item {
				height: 100%;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				background-color: #ffffff;
				border: 1px solid #e5e7eb;

				transition: all 0.25s ease;

				.mech_item_wrap {
					flex: 1;
					width: 100%;

					display: flex;
					flex-direction: column;
					align-items: center;

					.p_title {
						margin-top: 20px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #353c58;
						}
					}

					.p_active {
						margin-top: 30px;

						p {
							font-family: HanSans-Medium;
							font-weight: 500;
						}
					}

					img {
						width: 34px;
						height: 34px;

						object-fit: contain;
					}

					.img_active {
						width: 66px;
						height: 66px;
					}

					ul {
						margin-top: 15px;
					}

					li {
						flex: 1;
						padding: 0 30px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #788296;
							text-align: center;
						}
					}
				}
			}
		}
	}

	.dsly_cases {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
		background-color: #ffffff;

		.case_wrap {
			height: 100%;

			display: flex;
			flex-direction: column;

			img {
				width: 100%;
				aspect-ratio: 2.45;
				object-fit: contain;
				background-color: rgba(0, 0, 0, 0.1);
			}

			.case_title {
				padding: 6.5%;
				padding-bottom: 0;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #788296;

					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 18px;
					color: #353c58;

					-webkit-line-clamp: 1;

					margin-bottom: 4%;
				}
			}
		}
	}

	.dsly_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>