<template>
	<div class="yjy_body">
		<div id="yjy_header" class="yjy_header_clean" >
			<div class="yjy_header_body">
				<div class="yjy_head_body_left">
					<div class="yjy_logo" @click="clickHomeMenum('#yjy_home', 0)">
						<img :src="logoImg" alt="" class="yjy_logo_img">
						<div class="yjy_logo_name">{{dataDto.name}}</div>
					</div>
				</div>
				<div v-if="!showHeaderMenu" class="yjy_menu">
					<template v-for="(item, index) in meumList">

						<div v-if="index == 1 || index == 2" :key="index">
							<el-dropdown placement="bottom-start" :ref="'dropdown' + index">
								<div :class="topIndex == item.indexTag ? 'yjy_menu_cell active' : 'yjy_menu_cell'">
									{{ item.title }}
								</div>
								<el-dropdown-menu slot="dropdown">
									<div v-if="index == 1" class="aboutUs_dropdown_gywm">
										<div class="aboutUs_dd_left">
											<div class="aboutUs_dropdown_header"
												@click="showDetailAction(null, item.indexTag)">
												<p>关于我们</p>
												<img src="../assets/yjysite/yjysite_arrow_right_black.png" />
											</div>

											<div class="aboutUs_dd_left_body">
												<ul>
													<li v-for="(data, aindex) in aboutusList" :key="aindex">
														<div class="aboutUs_dd_li_wrap"
															@click="showDetailAction(data, item.indexTag)">
															<a>{{ data }}</a>
															<div class="aboutUs_dd_left_li_line"></div>
														</div>
													</li>
												</ul>
											</div>
										</div>
										<div class="aboutUs_dd_right">
											<div class="aboutUs_dd_right_wrap">
												<div class="aboutUs_dd_cpzx">
													<p>产品咨询</p>
													<p class="p2" @click="onLeviteated(1)">{{ dataDto.phone }}</p>
												</div>
												<div class="aboutUs_dd_cpzx" style="margin-top: 30px;">
													<p>人才引进</p>
													<p class="p2">{{ dataDto.email }}</p>
												</div>
												<div class="aboutUs_dd_qr_wrap">
													<div class="aboutUs_dd_qr_item" v-for="(item, index) in qrList"
														:key="index">
														<div class="aboutUs_dd_qr_item_img">
															<img :src="item.imageUrl" />
														</div>
														<div class="aboutUs_dd_qr_item_title">{{ item.title }}</div>
													</div>
												</div>
											</div>

										</div>
									</div>
									<div v-else class="aboutUs_dropdown_jjfa">
										<div class="aboutUs_dropdown_jjfa_body">
											<div class="aboutUs_dropdown_header"
												@click="showDetailAction(null, item.indexTag)">
												<p>查看所有解决方案</p>
												<img src="../assets/yjysite/yjysite_arrow_right_black.png" />
											</div>

											<div class="jjfa_ul_wrap">
												<ul>
													<li v-for="(plan, row) in ddPlans" :key="row"
														:style="{ marginTop: (row == 0) ? '0':'48px'}">
														<div class="jjfa_li_wrap">
															<p>{{ plan.title }}</p>
															<ul>
																<li v-for="(data, pIndex) in plan.plans" :key="pIndex"
																	:style="{ marginTop: (pIndex/4 < 1) ? '0':'24px'}">
																	<div class="aboutUs_dd_li_wrap"
																		@click="showDetailAction(data, item.indexTag)">
																		<a>{{ data.title }}</a>
																		<div class="aboutUs_dd_left_li_line"></div>
																	</div>
																</li>
															</ul>

														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div v-else class="yjy_menu_cell" :class="{ active: topIndex == item.indexTag }"
							@click="clickHomeMenum(item.route, item.indexTag)" :key="index">
							{{ item.title }}
						</div>

					</template>
				</div>
				<div class="yjy_head_body_right">

					<div class="yjy_menu_phone" @mouseenter="menuPhoneHover = true" @mouseleave="menuPhoneHover = false"
						@click="onLeviteated(1)">
						<img :src="menuPhoneHover ? menuPhoneHoverImg:menuPhoneImg" />
						<p :class="{'phone_p-hover': menuPhoneHover}">{{ dataDto.phone }}</p>
					</div>
					<div class="yjy_head_right_text" @click="onLogin">
						<p>进入系统</p>
					</div>

					<div v-show="showHeaderMenu" class="yjy_header_menu">
						<el-dropdown trigger="click" placement="bottom" @visible-change="onMeunDropDownShow"
							@command="showProductDetailAction">

							<img :src="meunDropDownShow ? menuCloseImg: menuImg" />

							<el-dropdown-menu slot="dropdown" style="width: 8rem;">
								<el-dropdown-item v-for="(dropItem, dropIndex) in meumList" :key="dropIndex"
									:command="dropIndex">
									<p>{{ dropItem.title }}</p>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>

				</div>
			</div>
		</div>

		<div style="background-color: transparent;height: 68px;"><!-- 占位 --></div>
		<div class="yjy_container">
			<router-view></router-view>
		</div>

		<div class="yjy_levitated" :style="{top: levitatedTop + 'px'}">
			<div class="levitated_item" v-for="(item, index) in levitatedDatas" :key="index"
				:style="{paddingTop: index == 0 ? '10px':'0', paddingBottom: (index == levitatedDatas.length - 1 && !isScrolled) ? '10px':'0'}"
				@click="onLeviteated(index)">

				<el-popover v-if="item.title === '电话咨询'" placement="left" trigger="hover">
					<div class="lev_tips">
						<p>联系客户服务经理</p>
						<p class="p_phone">{{ dataDto.mobile2 }}</p>
						<!-- <div class="lev_tips_phone">
							<p class="p_copy" @click="onPhoneCopy">复制</p>
						</div> -->
					</div>
					<div class="lev_item" slot="reference">
						<div class="lev_img">
							<img :src="item.img" :style="{width: item.imgW + 'px'}" />
						</div>
						<p>{{ item.title }}</p>
					</div>
				</el-popover>

				<div class="lev_item" v-else>
					<div class="lev_img">
						<img :src="item.img" :style="{width: item.imgW + 'px'}" />
					</div>
					<p>{{ item.title }}</p>
				</div>
			</div>
			<div v-show="isScrolled" class="levitated_item" style="padding-bottom: 10px;" @click="onLeviteated(3)">
				<div class="lev_item">
					<div class="lev_img">
						<img :src="levitatedTopData.img" :style="{width: levitatedTopData.imgW + 'px'}" />
					</div>
					<p>{{ levitatedTopData.title }}</p>
				</div>
			</div>
		</div>

		<div class="yjy_footer">
			<div class="footer_wrap">
				<div class="yjy_footer_connection">
					<div class="connection_body">
						<p>在线获取解决方案或预约演示系统</p>
						<div class="connection_text_bold">
							<p class="connection_num" @click="onLeviteated(1)">{{ ' '  + dataDto.phone }}</p>
							<p>/</p>
							<p class="connection_num" @click="onLeviteated(10)">{{ '' + dataDto.mobile }}</p>
						</div>
						<div class="connection_appointment" @click="onAppointment">
							<img src="../assets/yjysite/yjysite_cpmmection_appointment.png" />
							<p>预约演示</p>
						</div>
					</div>
				</div>

				<div class="yjy_footer_top yjy_footer_suit">
					<div class="yjy_footer_top_item" v-for="(item, index) in peculiarityList" :key="index">
						<img :src="item.imageUrl" class="yjy_footer_top_img" />
						<div class="yjy_footer_top_text_wrap">
							<div class="yjy_footer_top_text" style="margin-bottom: 5px;">{{ item.title }}</div>
							<div class="yjy_footer_top_text">{{ item.subtitle }}</div>
						</div>
					</div>
				</div>

				<div class="yjy_footer_body yjy_footer_suit">

					<div class="yjy_footer_body_wrap">
						<div class="yjy_footer_body_item" style="flex: 2.2;">
							<div class="yjy_footer_body_title">解决方案</div>
							<div class="footer_body_item">
								<ul v-for="(plans, index) in plansList" :key="index">
									<li v-for="(item, aIndex) in plans" :key="aIndex">
										<div class="yjy_footer_body_text footer_marginbottom"
											@click="showDetailAction(item, 2)">
											<p>{{ item.title }}</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="yjy_footer_body_item" style="flex:1;">
							<div class="yjy_footer_body_title">关于我们</div>
							<ul>
								<li class="footer_body_item_li2" v-for="(item, index) in aboutusList" :key="index">
									<div class="yjy_footer_body_text footer_marginbottom"
										@click="showDetailAction(item, 1)">
										<p>{{ item }}</p>
									</div>
								</li>
							</ul>
						</div>

						<div class="yjy_footer_body_item">
							<div class="yjy_footer_body_title">联系我们</div>
							<div class="yjy_footer_body_text2 footer_marginbottom">
								<p>合作咨询</p>
								<p class="text_margin">{{ dataDto.email  }}</p>
							</div>
							<div class="yjy_footer_body_text2 footer_marginbottom">
								<p>联系电话</p>
								<p class="footer_phone_num text_margin" @click="onLeviteated(1)">
									{{ '  '  + dataDto.phone }}
								</p>
								<p class="footer_phone_num text_margin" @click="onLeviteated(10)">
									{{ '  ' + dataDto.mobile }}
								</p>
							</div>
							<div class="yjy_footer_body_text2 footer_marginbottom">
								<p>地址</p>
								<p class="text_margin">{{ dataDto.addr }}</p>
							</div>
						</div>

						<div class="yjy_fotter_qr">
							<div class="yjy_fotter_qr_item" v-for="(item, index) in qrList" :key="index">
								<div class="yjy_fotter_qr_item_img">
									<img :src="item.imageUrl" alt="" />
								</div>
								<div class="yjy_fotter_qr_item_title">
									<p>{{ item.title }}</p>
								</div>
							</div>
						</div>
					</div>

					<div class="yjy_footer_body_bottom">
						<p @click="onOutsideLink">友情链接：共生物流平台</p>
					</div>
				</div>

				<div class="yjy_fotter_bottom yjy_footer_suit">
					<p>gtc56.com 皖ICP备2020018219号-1 @2020 安徽共生众服供应链技术研究院有限公司</p>
				</div>
			</div>
		</div>

		<div v-if="showSuggestTag" class="yjy_suggest">
			<div class="yjy_suggest_top_close" @click="showSuggestTag=false">
				<img :src="closeImg" alt="" />
			</div>
			<div class="yjy_suggest_center">
				<el-input placeholder="您的姓名（必填）" v-model="sendDto.name" class="suggest_input"
					style="margin-top:10px"></el-input>
				<el-input placeholder="您的电话（必填）" v-model="sendDto.mobile" class="suggest_input"></el-input>
				<el-input placeholder="您的公司名称（必填）" v-model="sendDto.addr" class="suggest_input"></el-input>
				<el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" maxlength="200" show-word-limit
					placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）" v-model="sendDto.msg" class="suggest_input_text"></el-input>
			</div>
			<div class="yjy_suggest_bottom">
				<div class="yjy_suggest_bottom_btn" @click="sendSuggestAction" :loading="sendLoding">发送</div>
			</div>
		</div>
	</div>
</template>


<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import {
		mapActions
	} from "vuex";
	import DevicePixelRatio from '../utils/devicePixelRatio'
	import clipboard from 'clipboard'
	import {
		bodyZoom
	} from "@/utils/utils.js"

	export default {
		data() {
			return {
				sendLoding: false,
				logoImg: require("@/assets/yjysite/yjy_home_logo.png"),
				showHeaderMenu: false,
				meunDropDownShow: false,
				menuImg: require("@/assets/yjysite/yjysite_navi_menu.png"),
				menuCloseImg: require("@/assets/yjysite/yjysite_navi_menu_close.png"),
				menuPhoneHover: false,
				menuPhoneImg: require('../assets/yjysite/yjysite_home_phone.png'),
				menuPhoneHoverImg: require('../assets/yjysite/yjysite_home_phone_hover.png'),
				closeImg: require('../assets/yjysite/yjysite_navi_menu_close.png'),

				phoneImg: require("@/assets/yjysite/yjysite_home_phone.png"),
				dataDto: {
					name: "共生供应链技术公司",
					phone: "400-111-5856", //400-111-5856
					phone2: '4001115856',
					mobile: "186 1408 1545",
					mobile2: "18614081545",
					email: "tianyu@gsh56.com",
					addr: "安徽省芜湖市镜湖区绿地新都会C座15楼",
				},

				isScrolled: true,
				levitatedTipsVisible: false,
				levitatedTop: 68,
				levitatedDatas: [{
						img: require("@/assets/yjysite/yjysite_levitated-1.png"),
						imgW: 23,
						title: '在线咨询',
					},
					{
						img: require("@/assets/yjysite/yjysite_levitated-2.png"),
						imgW: 19,
						title: '电话咨询'
					},
					{
						img: require("@/assets/yjysite/yjysite_levitated-3.png"),
						imgW: 23,
						title: '预约演示'
					}
				],
				levitatedTopData: {
					img: require("@/assets/yjysite/yjysite_levitated-4.png"),
					imgW: 11,
					title: '返回顶部'
				},

				topIndex: 0,
				showSuggestTag: false,

				meumList: [{
						title: "首页",
						route: "yjysite",
						indexTag: 0,
						showTag: true
					},
					{
						title: "关于我们",
						route: "yjyAboutUs",
						indexTag: 1,
						showTag: false
					},
					{
						title: "解决方案",
						route: "yjySolutions",
						indexTag: 2,
						showTag: false
					},
					{
						title: "客户案例",
						route: "yjyCustomerCases",
						indexTag: 3,
						showTag: false
					},
					{
						title: "前沿技术",
						route: "qyjs",
						indexTag: 4,
						showTag: false
					},
					{
						title: "荣誉资质",
						route: "ryzz",
						indexTag: 5,
						showTag: false
					},
					{
						title: "最新动态",
						route: "yjyLatestNews",
						indexTag: 6,
						showTag: false
					},
					{
						title: "物流平台",
						route: "https://gsh56.com/",
						indexTag: 7,
						showTag: false
					}
				],
				// 下拉弹出列表
				ddPlans: [{
						title: '运输解决方案',
						plans: [{
							index: 1,
							title: '智能运输解决方案',
							route: 'znys',
						}, {
							index: 3,
							title: '网络货运解决方案',
							route: 'wlhy'
						}, {
							index: 5,
							title: '数字水运解决方案',
							route: 'szsy'
						}, {
							index: 7,
							title: '多式联运解决方案',
							route: 'dsly'
						}]
					},
					{
						title: '仓储解决方案',
						plans: [{
							index: 9,
							title: '智能仓储解决方案',
							route: 'zncc'
						}]
					},
					{
						title: '园区解决方案',
						plans: [{
							index: 11,
							title: '智能物流园区解决方案',
							route: 'znwlyq',
						}]
					},
					{
						title: '人力解决方案',
						plans: [{
							index: 13,
							title: '众包产品介绍-灵工解决方案',
							route: 'lhyg'
						}, {
							index: 15,
							title: '汇用工解决方案',
							route: 'hyg'
						}]
					},
					{
						title: 'SaaS解决方案',
						plans: [{
							index: 17,
							title: '物流SaaS',
							route: 'saas56'
						}, {
							index: 2,
							title: '人力SaaS',
							route: 'saashr'
						}]
					},
					{
						title: '平台解决方案',
						plans: [{
								index: 4,
								title: '城市物流资源整合平台',
								route: 'cswlpt'
							}, {
								index: 6,
								title: '物流与供应链大数据平台',
								route: 'dsjpt'
							},
							// {
							// 	index: 8,
							// 	title: '县域综合物流平台',
							// 	linkurl: 'https://www.baidu.com/'
							// },
							{
								index: 10,
								title: '细分行业供应链服务平台',
								route: 'gylpt'
							}, {
								index: 12,
								title: '共享IT平台',
								route: 'gxitb'
							}
						]
					},
					{
						title: '其他解决方案',
						plans: [{
							index: 14,
							title: '供应链控制塔',
							route: 'gylkzt'
						}, {
							index: 16,
							title: '物流招投标解决方案',
							route: 'ztb'
						}]
					}
				],

				peculiarityList: [{
						title: "7*24h",
						subtitle: "多渠道服务支持",
						imageUrl: require("@/assets/yjysite/peculiarity1.png")
					},
					{
						title: "1000+",
						subtitle: "企业应用广泛",
						imageUrl: require("@/assets/yjysite/peculiarity2.png")
					},
					{
						title: "专业服务",
						subtitle: "产品服务有保障",
						imageUrl: require("@/assets/yjysite/peculiarity3.png")
					},
					{
						title: "安全保障",
						subtitle: "稳定 安全 可靠",
						imageUrl: require("@/assets/yjysite/peculiarity4.png")
					}
				],
				plansList: [
					[{
							title: '智能运输解决方案',
							route: 'znys',
						}, {
							title: '网络货运解决方案',
							route: 'wlhy'
						}, {
							title: '数字水运解决方案',
							route: 'szsy'
						}, {
							title: '多式联运解决方案',
							route: 'dsly'
						},
						{
							title: '智能仓储解决方案',
							route: 'zncc'
						},
						{
							title: '智能物流园区解决方案',
							route: 'znwlyq',
						},
						{
							title: '物流SaaS',
							route: 'saas56'
						},
						{
							title: '人力SaaS',
							route: 'saashr'
						},
						{
							title: '城市物流资源整合平台',
							route: 'cswlpt'
						},
						{
							title: '物流与供应链大数据平台',
							route: 'dsjpt'
						}
						// , {
						// 	title: '县域综合物流平台',
						// 	linkurl: 'https://www.baidu.com/'
						// }
					],
					[{
							title: '细分行业供应链服务平台',
							route: 'gylpt'
						},
						{
							title: '共享IT平台',
							route: 'gxitb'
						},
						{
							title: '供应链控制塔',
							route: 'gylkzt'
						},
						{
							title: '物流招投标解决方案',
							route: 'ztb'
						},
						{
							title: '众包产品介绍-灵工解决方案',
							route: 'lhyg'
						},
						{
							title: '汇用工解决方案',
							route: 'hyg'
						}
					]
				],
				aboutusList: ['公司介绍', '团队介绍', '发展历程', '企业荣誉', '联系我们'],
				//  shengganQR.png  yjy_home_qr_2.png
				qrList: [{
						title: "扫码关注微信",
						imageUrl: require("@/assets/yjy/shengganQR.png"),
					},
					{
						title: "关注微信公众号",
						imageUrl: require("@/assets/yjy/yjy_home_qr_1.png"),
					},
				],
				user: null,
				webLic: null,
				logoConfig: {},
				sendDto: {
					name: "",
					mobile: "",
					addr: "",
					msg: "",
				},
			};
		},

		created() {
			//解决笔记本初始 150%的屏幕问题
			new DevicePixelRatio().init();

			var _hmt = _hmt || [];
			(function() {
				var hm = document.createElement("script");
				hm.src = "https://hm.baidu.com/hm.js?ace583b6491a6f2b7fcab7df6df7963e";
				var s = document.getElementsByTagName("script")[0];
				s.parentNode.insertBefore(hm, s);
			})(window, document, 'script', 'bdhm');

			that.getCompanyInfo();
		},

		mounted() {
			var that = this;

			let path = that.$route.path
			if (path != null && path.indexOf("yjyAboutUs") != -1) {
				that.topIndex = 1;
			}
			if (path != null && path.indexOf("solution/") != -1) {
				that.topIndex = 2
			}

			this.handleResize()
			window.addEventListener('resize', this.handleResize);
			// window.addEventListener('scroll', this.handleScroll);
			window.addEventListener('popstate', this.popstate);
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize);
			// window.removeEventListener('scroll', this.handleScroll);
			window.removeEventListener('popstate', this.popstate);
		},

		methods: {
			...mapActions(["user/logout"]),
			
			popstate() {
				console.log('popstate change :' + window.location.href)
				let href = window.location.href
				let hrefs = href.split('#')

				if (hrefs && hrefs.length >= 2) {
					let path = hrefs[1]
					if (path.includes('/yjysite')) {
						if (path.includes('qyjs')) {
							this.topIndex = 4
						} else if (path.includes('ryzz')) {
							this.topIndex = 5
						} else {
							this.topIndex = 0
						}
					}
					if (path.includes('/yjyAboutUs')) {
						this.topIndex = 1
					}
					if (path.includes('/yjySolutions') || path.includes('/solution')) {
						this.topIndex = 2
					}
					if (path.includes('/yjyCustomerCases')) {
						this.topIndex = 3
					}
					if (path.includes('/yjyLatestNews')) {
						this.topIndex = 6
					}
				}
			},
			handleResize() {
				// console.log('屏幕尺寸变化了 innerWidth:' + window.innerWidth + '--innerHeight:' + window.innerHeight);
				let zoom = bodyZoom()
				if (window.innerWidth < 1366 * zoom) {
					this.showHeaderMenu = true
				} else {
					this.showHeaderMenu = false;
				}

				let w = window.innerWidth > 1920 * zoom ? 1920 * zoom : window.innerWidth
				w = w < 1366 * zoom ? 1366 * zoom : w

				this.levitatedTop = 68 + w * 414 / 1920
				if (window.innerHeight < 276) {
					this.levitatedTop = window.innerHeight - 276
				}
			},
			handleScroll() {
				let scrolled = window.scrollY > 0
				this.isScrolled = scrolled
			},

			handlePlans() {
				if (this.ddPlans.length > 0) {
					let plans = []
					this.ddPlans.forEach(item => {
						if (item.plans.length > 0) {
							plans = [...plans, ...item.plans]
						}
					})
					plans.sort((a, b) => a.index - b.index)
					// this.plansList = plans
					this.$set(this, 'plansList', plans)
				}
			},

			levitatedMouseMove(e) {
				console.log('levitated move:' + JSON.stringify(e));

				let el = event.target
				console.log('el:' + el + 'el id:' + el.id)
			},

			levitatedenter(index) {
				console.log('levitated enter:' + index);
				if (this.levitatedTipsVisible == true) return
				this.levitatedTipsVisible = true
			},
			levitatedleave(index) {
				console.log('levitated leave' + index);
				if (this.levitatedTipsVisible == false) return
				this.levitatedTipsVisible = false
			},

			onAppointment() {
				this.dhzx()
			},
			onMeunDropDownShow(e) {
				console.log('onMeunDropDownShow:' + e);
				this.meunDropDownShow = e;
			},

			//跳转到详情
			showProductDetailAction(index) {
				this.clickHomeMenum(null, index)
			},
			showDetailAction(item, indexTag) {
				let that = this;

				that.topIndex = parseInt(indexTag)
				console.log('showDetailAction item:' + JSON.stringify(item) + 'indexTag:' + indexTag)

				let path = '';
				if (that.topIndex == 1) {
					// 关于我们
					path = 'yjyAboutUs?topIndex=' + that.topIndex
					if (item != null) {
						path += ('&scrollRef=' + item)
					}
				} else if (that.topIndex == 2) {
					// 解决方案
					if (item == null) {
						path += 'yjySolutions'
					} else {
						let fillName = item.route + 'Solution'
						path = 'solution/' + fillName
					}
				}
				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/" + path;
				console.log('showDetailAction:' + url + '-- topIndex:' + that.topIndex);

				document.documentElement.scrollTop = 0;
				window.location.href = url;
			},
			clickHomeMenum(selector, index) {
				let that = this;
				this.topIndex = parseInt(index);
				if (selector == null && index < this.meumList.length) {
					selector = this.meumList[index].route
				}
				if (selector && selector.startsWith('https://')) {
					window.open(selector);
					return
				}

				let path = ''
				if (selector === 'qyjs' || selector === 'ryzz') {
					path = 'yjysite?scrollRef=' + selector
				} else {
					path = selector
				}
				console.log('clickHomeMenum:' + path + '--- index: ' + index)
				var url =
					window.location.protocol +
					"//" +
					window.location.host +
					"/yjysite.html#/" +
					path;
				document.documentElement.scrollTop = 0;
				window.location.href = url;
			},

			onSwitchTap(index) {
				if (index == 1 || index == 2) {
					this.showDetailAction(null, index)
				} else {
					this.clickHomeMenum(null, index)
				}
			},

			onLeviteated(index) {
				console.log('onLeviteated:' + index)
				if (index == 0) {
					// 在线咨询
					var ele = document.querySelector('.embed-chat-container');
					if (ele && ele.style.height !== '0px') return

					var target = document.querySelector('.embed-icon-default');
					if (target) target.click()

				} else if (index == 1) {
					window.location.href = 'tel:' + this.dataDto.phone2
				} else if (index == 10) {
					window.location.href = 'tel:' + this.dataDto.mobile2
				} else if (index == 2) {
					this.dhzx()
				} else {
					if (document.documentElement.scrollTop > 0) {
						document.documentElement.scrollTop = 0
					}
				}
			},

			onPhoneCopy() {
				let text = this.dataDto.phone2
				clipboard.copy(text);
				this.$message.success('已复制到剪贴板');
			},
			onOutsideLink() {
				console.log('onOutsideLink')
				// window.open('https://gsh56.com/')
				this.clickHomeMenum(null, 7)
			},

			getCompanyInfo() {
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getCompanyInfo").then((res) => {
					if (res.data.success) {
						var dataDict = res.data.data;
						if (dataDict.user != null && dataDict.user.id != null) {
							that.user = dataDict.user;
						}
						if (dataDict.logoConfig != null) {
							that.logoConfig = dataDict.logoConfig;
							// that.logoImg = dataDict.logoConfig.imageUrl;
						}
						if (dataDict.webLic != null) {
							that.webLic = dataDict.webLic;
						}
					}
				});
			},
			onLogin() {
				// 点击登录
				var url = 'https://saas.gsh56.com/saas.html#/saas';
				console.log('onLogin:' + url);
				window.open(url);
			},

			// 在线咨询
			zxzx() {
				this.onLeviteated(0)
			},
			// 电话咨询
			dhzx() {
				this.showSuggestTag = !this.showSuggestTag;
			},
			// 保存留言
			sendSuggestAction() {
				let that = this;
				if (this.sendDto.name == "" || this.sendDto.mobile == "" || this.sendDto.addr == "" || this.sendDto.msg ==
					"") {
					that.$message.warning("请完善必填项！");
					return;
				}
				let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
				if (!re.test(this.sendDto.mobile)) {
					that.$message.warning("请输入数字值");
					return;
				} else {
					if (this.sendDto.mobile.length != 11) {
						that.$message.warning("手机号格式不正确");
						return;

					}
				}
				that.sendLoding = true;
				aEntityAPI.request("HomePageConfigService", "saveYjySuggest", {
					suggestDto: JSON.stringify(this.sendDto)
				}).then((res) => {
					that.sendLoding = false;

					if (res.data.success) {
						this.sendDto = {
								name: "",
								mobile: "",
								addr: "",
								msg: "",
							},
							this.$message({
								message: '您已发送成功',
								type: 'success'
							});
					}
				});
			}
		},
	};
</script>

<style lang="scss">
	body {
		min-width: 1200px;
		overflow: scroll !important;
		height: 100%;

		font-size: 16px;
	}

	.yjy_body {
		background-color: #F6F7F8;
		/* min-width: 1200px; */
		// overflow: hidden;
		height: 100%;
	}

	.body_width_suit {
		width: 100%;
		max-width: 1440px;
		min-width: 1200px;
	}

	.yjy_container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}


	.yjy_header_clean {
		height: 68px;
		height: 4.25rem;
		// min-width: 1200px;
		background: #ffffff;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;

		display: flex;
		align-items: center;
		z-index: 22;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
	}


	.yjy_header_body {
		width: 100%;
		height: 100%;
		margin: 0 auto;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.yjy_head_body_left {

		display: flex;
		justify-content: space-between;
		align-items: center;
		justify-content: center;
		background-color: white;

		width: 220px;
		margin-left: 2.5rem;

		.yjy_logo {
			display: flex;
			align-items: center;
		}

		.yjy_logo_img {
			width: 31px;
			margin-right: 10px;
		}

		.yjy_logo_name {
			/* font-size: 18px;
			font-family: SourceHanSansCN-Medium;
			font-weight: 400;
			color: #4B6FC8;
			line-height: 22px; */

			font-family: HanSans-Medium;
			font-weight: 500;
			font-size: 20px;
			color: #333333;
			line-height: 22px;

			flex-shrink: 0;
		}

		.yjy_logo:hover {
			cursor: pointer;
		}
	}

	.yjy_menu {
		flex: 1;

		height: 50%;
		padding: 0 35px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

	}

	.el-dropdown-menu {
		// position: absolute !important;
		// top: 3.125rem !important;
		// left: 400px !important;
	}

	.el-dropdown-menu__item {
		padding: 10px !important;
	}

	.yjy_menu:hover {
		cursor: pointer;
	}

	.yjy_menu_cell {
		display: flex;
		justify-content: flex-end;

		padding: 0 25px;

		color: #333333;
		font-family: HanSans-Normal;
		font-weight: 400;
		font-size: 16px;

		flex-shrink: 0;
	}

	.yjy_menu_cell:hover {
		color: #4B6FC8 !important;
		font-family: Source Han Sans, Source Han Sans !important;
	}

	.active {
		color: #0055FF !important;
	}

	.aboutUs_dropdown_gywm {
		width: 823px;
		height: 346px;
		background-color: #FFFFFF;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

		display: flex;

		.aboutUs_dd_left {
			flex: 1;

			display: flex;
			flex-direction: column;

			padding-left: 52px;
			padding-right: 75px;

			.aboutUs_dd_left_body {

				padding-top: 30px;

				ul {
					display: flex;
					flex-wrap: wrap;
				}

				li {
					flex-basis: 33.3%;
					margin-bottom: 50px;

					display: flex;
				}
			}
		}

		.aboutUs_dd_right {
			width: 240px;
			height: 100%;

			background-color: #F7F8FC;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

			.aboutUs_dd_right_wrap {
				margin: 30px 24px 40px 24px;

				display: flex;
				flex-direction: column;
				/* justify-content: space-between; */

				.aboutUs_dd_cpzx {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;

					p {
						font-family: Source Han Sans, Source Han Sans;
						font-weight: 400;
						font-size: 14px;
						color: #5B627E;
						line-height: 14px;
						text-align: left;
					}

					.p2 {
						margin-top: 14px;

						font-weight: 400;
						font-size: 16px;
						color: #353C58;
						line-height: 16px;

						text-decoration: none;
					}

					.p2:hover {
						text-decoration: underline !important;
					}
				}

				.aboutUs_dd_qr_wrap {
					height: 102px;
					margin-top: 50px;

					display: flex;
					align-items: center;
					justify-content: space-between;

					.aboutUs_dd_qr_item {
						display: flex;
						flex-direction: column;
						align-items: center;

						.aboutUs_dd_qr_item_title {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 12px;
							color: #64698B;
							line-height: 12px;
							text-align: center;

							margin-top: 5px;
						}

						.aboutUs_dd_qr_item_img {
							width: 80px;
							height: 80px;

							img {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}



		}
	}

	.aboutUs_dropdown_jjfa {
		width: 1281px;
		height: 640px;
		background-color: #FFFFFF;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

		display: flex;

		.aboutUs_dropdown_jjfa_body {
			flex: 1;
			padding: 0 178px 30px 52px;

			display: flex;
			flex-direction: column;

			.jjfa_ul_wrap {
				/* flex: 1; */
				width: 100%;

				display: flex;
				flex-direction: column;
				align-items: flex-start;

				ul {
					width: 100%;
					margin-top: 30px;
				}
			}

			.jjfa_li_wrap {
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				p {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 18px;
					color: #0055FF;
					line-height: 18px;
					text-align: left;

					flex-shrink: 0;

					min-width: 22%;
				}

				ul {
					flex-wrap: wrap;
					list-style: none;
					margin: 0;

					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
				}

				li {
					display: inline-block;
					flex-basis: 25%;

					display: flex;
					align-items: center;
				}
			}
		}
	}

	.aboutUs_dropdown_header {
		height: 83px;

		border-bottom: 1px solid #EBECF0;

		display: flex;
		/* justify-content: center; */
		align-items: center;

		p {

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 20px;
			color: #333333;
			line-height: 24px;
			text-align: left;

			margin-right: 14px;

			text-decoration: none;
		}

		p:hover {
			text-decoration: underline !important;

			margin-right: 20px !important;

			cursor: pointer;
		}

		img {
			height: 16px;
		}
	}

	.aboutUs_dd_li_wrap {

		display: flex;
		flex-direction: column;

		a {
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 16px;
			color: #353C58;
			text-align: left;

			text-decoration: none;
		}

		a:hover {
			cursor: pointer;
		}

		.aboutUs_dd_left_li_line {
			/* margin-top: 2px; */
			height: 1px;
			background-color: transparent;
		}
	}

	.aboutUs_dd_li_wrap:hover {
		a {
			color: #0055FF !important;
		}

		.aboutUs_dd_left_li_line {
			background-color: #0055FF !important;
		}
	}

	.yjy_head_body_right {
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
		background-color: white;
		padding-left: 20px;

		.yjy_menu_phone {

			display: flex;
			align-items: center;

			img {
				height: 15px;
				margin-right: 10px;
			}

			p {
				font-family: HanSans-Blod;
				font-weight: 700;
				font-size: 20px;
				color: #333333;
				line-height: 36px;

				text-decoration: none;
				flex-shrink: 0;
			}

			.phone_p-hover {
				color: #4B6FC8 !important;
			}
		}

		.yjy_menu_phone:hover {
			cursor: pointer;
		}


		.yjy_head_right_text {
			/* flex: 1; */
			height: 100%;
			aspect-ratio: 1.5;

			background-color: #0055FF;
			margin-left: 15px;

			display: flex;
			justify-content: center;
			align-items: center;

			p {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 14px;
				text-align: center;
			}

			p:hover {
				cursor: pointer;
			}
		}

		.yjy_header_menu {
			padding: 0 15px;

			img {
				width: 20px;
			}

			img:hover {
				cursor: pointer;
			}

			/* .menu-title {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				line-height: 14px;
				text-align: center;
			}
			.menu-title:hover {
				color: #0055FF !important;
			} */
		}
	}

	.yjy_footer {
		width: 100%;

		display: flex;
		justify-content: center;
	}

	.footer_wrap {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
		flex: 1;
		padding-bottom: 40px;

		display: flex;
		flex-direction: column;
		align-items: center;

		background-color: #FFFFFF;
	}

	.yjy_footer_connection {
		width: 100%;
		height: 240px;
		margin-top: 70px;

		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("../assets/yjysite/yjysite_connection_bg.png");

		display: flex;
		justify-content: center;
		align-items: center;

		.connection_body {

			flex: 1;

			display: flex;
			flex-direction: column;
			/* justify-content: space-between; */
			align-items: center;

			p {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 16px;
				color: #3D4461;
				line-height: 20px;
				text-align: center;
			}

			.connection_text_bold {

				display: flex;
				align-items: center;

				margin-top: 21px;

				p {
					font-family: DINPro, DINPro;
					font-weight: 700;
					font-size: 36px;
					color: #353C58;
					line-height: 36px;
					text-align: center;
				}

				.connection_num {
					font-family: DINPro, DINPro;
					font-weight: 700;
					font-size: 36px;
					color: #353C58;
					line-height: 36px;
					text-align: center;

					border-bottom: 1px solid #353C58;
				}

				.connection_num:hover {
					cursor: pointer;
				}
			}

			.connection_appointment {
				display: flex;
				justify-content: center;
				align-items: center;

				width: 148px;
				height: 46px;
				background-color: #0055FF;
				border-radius: 23px;

				margin-top: 21px;

				img {
					width: 14px;
					margin-right: 4px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 14px;
					text-align: center;
				}
			}

			.connection_appointment:hover {
				cursor: pointer;
				background-color: #3377FF;
			}
		}
	}

	.yjy_footer_suit {
		width: 75%;
		min-width: 1200px;
		max-width: 1440px;
	}

	.yjy_footer_top {

		display: flex;
		align-items: center;
		justify-content: space-between;

		margin-bottom: 20px;
		padding: 20px 0;
		border-bottom: 1px solid #E5E7EB;
	}



	.yjy_footer_body {
		/* min-width: 1000px;
		width: 80%; */
		/* width: 1440px; */

		display: flex;
		flex-direction: column;

		padding-top: 30px;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #E5E7EB;

	}

	.yjy_footer_body_wrap {
		width: 100%;

		display: flex;
		align-items: flex-start;
		justify-content: flex-end;

		padding-bottom: 5px;

		.yjy_footer_body_item {

			.footer_body_item {
				display: grid;
				grid-template-columns: repeat(2, 1fr);

			}

			.yjy_footer_body_title {

				font-family: HanSans-Medium;
				font-weight: 500;
				font-size: 16px;
				color: #3D4461;
				line-height: 16px;
				text-align: left;

				margin-bottom: 20px;
				margin-bottom: 1.25rem;
			}

			.footer_marginbottom {
				margin-bottom: 20px;
				margin-bottom: 1.25rem;
			}

			.yjy_footer_body_text {
				flex: 1;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 13px;
					color: #64698B;
					line-height: 13px;
					text-align: left;
				}

				p:hover {
					color: #0055FF !important;
					cursor: pointer;
				}
			}

			.yjy_footer_body_text2 {

				display: flex;
				flex-direction: row;
				align-items: center;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 13px;
					color: #64698B;
					line-height: 13px;
					text-align: left;
				}

				.text_margin {
					margin-left: 0.525rem;
				}

				.footer_phone_num {
					font-family: HanSans-Blod;
					font-weight: 700;
				}

				.footer_phone_num:hover {
					cursor: pointer;
				}
			}

		}
	}

	.yjy_footer_body_bottom {

		margin-top: 16px;

		p {
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 14px;
			color: #64698B;
			line-height: 17px;
			text-align: left;
		}

		p:hover {
			color: #0055FF !important;
		}
	}

	.yjy_fotter_bottom {

		p {
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 14px;
			color: #64698B;
			line-height: 17px;
			text-align: left;

		}
	}

	.yjy_footer_top_item {
		/* flex: 1; */
		height: 100%;

		display: flex;
		align-items: flex-start;

		padding-right: 20px;

		/* background-color: red; */
	}

	.yjy_footer_top_img {
		width: 32px;
		margin-right: 10px;
	}

	.yjy_footer_top_text_wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.yjy_footer_top_text {
		font-family: HanSans-Blod;
		font-weight: 700;
		font-size: 16px;
		color: #64698B;
		line-height: 16px;
	}

	.yjy_footer_icon {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.yjy_footer_img {
		width: 52px;
		margin-right: 10px;

	}

	.yjy_footer_name {
		font-size: 18px;
		font-family: MF-LangQian-Regular, MF-LangQian;
		font-weight: 400;
		color: #FFFFFF;
	}

	.yjy_fotter_info {
		display: flex;
		flex: 2;
		flex-direction: column;
		justify-content: space-between;
		justify-content: center;
		height: 100%;
		margin-left: 80px;
	}

	.yjy_fotter_info_title {
		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #bbbdc5;
		margin-bottom: 10px;
	}

	.yjy_fotter_info_phone {
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.yjy_fotter_info_spc {
		margin-top: 20px;
	}


	.yjy_fotter_info_sub {

		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #bbbdc5;
	}

	.yjy_fotter_qr {
		width: 240px;

		display: flex;
		flex-direction: row;
		align-items: center;

		margin-left: 140px;
		margin-left: 8.75rem;

		.yjy_fotter_qr_item {
			display: flex;
			flex-direction: column;
			align-items: center;

			margin-left: 20px;

			.yjy_fotter_qr_item_title {

				margin-top: 10px;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 12px;
					color: #64698B;
					line-height: 12px;
				}
			}

			.yjy_fotter_qr_item_img {
				width: 100px;
				height: 100px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	/* 悬浮球 */
	.yjy_levitated {
		position: fixed;
		overflow: hidden;

		top: 168px;
		right: 1%;

		width: 64px;
		z-index: 22;

		background: linear-gradient(180deg, #75BCFD 0%, #448DFC 100%);
		border-radius: 32px;

		display: flex;
		flex-direction: column;

		.levitated_item {

			display: flex;
			flex-direction: column;

			background-color: transparent;

			position: relative;

			.lev_item {
				aspect-ratio: 1;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				p {
					pointer-events: none;
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 12px;
					color: #FFFFFF;
					line-height: 12px;
					text-align: center;
				}

				.lev_img {

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					width: 23px;
					aspect-ratio: 1;

					margin-bottom: 7px;
					pointer-events: none;

					img {
						width: 23px;
					}
				}
			}
		}

		.levitated_item:hover {
			background-color: #267AFC !important;
			cursor: pointer;
		}
	}

	.lev_tips {
		display: flex;
		flex-direction: column;

		p {
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			font-size: 12px;
			color: #353C58;
		}

		.p_phone {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 14px;
			color: #353C58;
		}

		.lev_tips_phone {
			display: flex;
			flex-direction: row;
			align-items: center;

			p {
				font-family: HanSans-Blod;
				font-weight: 700;
				font-size: 14px;
				color: #353C58;
			}

			.p_copy {
				margin-left: 8px;
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 12px;
				color: #0055FF;
			}

			.p_copy:hover {
				cursor: pointer;
			}
		}
	}

	/* 留言板的样式 */
	.yjy_suggest {
		position: fixed;
		right: 86px;
		bottom: 0px;
		width: 306px;
		height: 446px;
		background: #F5F7FA;
		box-shadow: 0px 0px 18px 6px rgba(0, 0, 0, 0.13);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #f5f7fa;
		z-index: 100;
	}

	.yjy_suggest_top_close {
		width: 1.5625rem;
		height: 1.5625rem;

		position: absolute;
		top: 0.3125rem;
		right: 0.3125rem;

		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 50%;
		}
	}

	.yjy_suggest_top_close:hover {
		cursor: pointer;
	}

	.yjy_suggest_top {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		background: #4b6fc8;
	}

	.yjy_suggest_top_title {
		font-size: 16px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
		margin: 15px 10px;
	}

	.yjy_suggest_top_btn {
		font-size: 16px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
		margin: 15px 10px;
	}

	.yjy_suggest_center {
		flex: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 80%;
	}

	.yjy_suggest_center_hidden {
		height: 0;
		overflow: hidden;
	}


	.yjy_suggest_bottom {
		width: 100%;
	}

	.yjy_suggest_bottom_hidden {
		height: 0;
		overflow: hidden;
	}

	.yjy_suggest_bottom_btn {
		float: right;

		width: 80px;
		height: 38px;
		background: #4B6FC8;
		border-radius: 4px;
		margin: 22px 23px;
		line-height: 38;
		text-align: center;
		background: #4b6fc8;
		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 38px;
		cursor: pointer;
	}

	.suggest_input .el-input__inner {
		height: 50px !important;
		line-height: 50px;
	}

	.suggest_input_text {}


	@font-face {
		font-family: HanSans-Blod;
		src: url("../font/SourceHanSansCN-Bold.otf");
	}

	@font-face {
		font-family: HanSans-Medium;
		src: url("../font/SourceHanSansCN-Medium.otf");
	}

	@font-face {
		font-family: HanSans-Normal;
		src: url("../font/SourceHanSansCN-Normal.ttf");
	}

	@font-face {
		font-family: Source Han Sans;
		src: url("../font/SourceHanSansCN-Regular.otf");
	}

	/* 动画效果 */
	/* 结束 */
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	/* 开始 */
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}
</style>