<!-- 物流Saas -->
<template>
	<div class="solution_body">
		<div class="solu_header body_suit item">
			<commonHeaderPage :model="header" :menus="menuList" @btnClick="headerBtnClick"
				@menuClick="headerMenuClick" />
		</div>

		<div v-if="scenes.length > 0" class="solu_scene body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>应用场景</p>
				</div>
				<div class="scene_wrap">
					<div class="scene" v-for="(scene, index) in scenes" :key="index">
						<img :src="__imagePath + scene.imgurl" alt="" />
						<div class="scene_title">
							<p class="p1">{{ scene.title }}</p>
							<ul>
								<li v-for="(sub, sIndex) in scene.subTitles" :key="sIndex">
									<p>{{ '· ' + sub }}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="architectureImg" :ref="menuList[1]" class="solu_architecture body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>解决方案架构图</p>
				</div>
				<div class="architecture_wrap">
					<img :src="architectureImg" alt="" />
				</div>
			</div>
		</div>
		<div v-if="functions.length > 0" :ref="menuList[2]" class="solu_functions body_suit item dray_background">
			<div class="item_wrap">
				<div class="item_header">
					<p>核心功能</p>
				</div>
				<div class="functions_wrap">
					<div class="function_wrap" v-for="(plan, index) in functions" :key="index">
						<p class="p1">{{ plan.title }}</p>
						<p>{{ plan.subTitle }}</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="resources.length > 0" :ref="menuList[3]" class="solu_resources body_suit item">
			<div class="item_wrap">
				<div class="item_header">
					<p>资源丰富</p>
				</div>
				<div ref="resourcesWrap" class="resources_wrap" :style="resourcesStyle">
					<div class="resource_item" v-for="(resource, index) in resources" :key="index"
						@mouseenter="onMouseenter(resource)" @mouseleave="onMouseleave(resource)">

						<div class="resource_item_wrap">
							<img :src="__imagePath + (resource.active ? resource.imageurlA:resource.imageurl)"
								:class="{'img_active': resource.active}" />
							<div class="p_title" :class="{'p_active': resource.active}">
								<p>{{ resource.title }}</p>
							</div>

							<ul v-show="resource.active">
								<li v-for="(sub, sIndex) in resource.subtitles" :key="sIndex">
									<p>{{ sub }}</p>
								</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div :ref="menuList[4]" class="solu_download body_suit item dray_background">
			<solutionDataView type="56saas" @downloadClick="onDownload" @videoClick="onVideo" />
		</div>

	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./commonView/commonHeaderPage.vue"
	import solutionDataView from "./commonView/solutionDataView.vue"
	import solutionCasesView from "./commonView/solutionCasesView.vue"
	import localPage from "../json/saas56Solution.json"

	export default {
		components: {
			commonHeaderPage,
			solutionDataView,
			solutionCasesView
		},
		data() {
			return {

				header: {
					title: '物流SaaS',
					subTitle: '软件定义运输  按需采购服务',
				},
				menuList: ['应用场景', '架构图', '核心功能', '资源丰富', '相关资料'],
				architectureImg: null,

				scenes: [],
				functions: [],

				resourcesStyle: null,
				resources: [],
			};
		},

		created() {},
		mounted() {
			this.loadDatas()
		},

		methods: {

			scrollToEL(e) {
				console.log('scrollToEL')
				let that = this;
				that.$nextTick(() => {
					const element = that.$refs[`${ e }`]
					console.log('element:' + element)
					if (element) {
						element.style.scrollMarginTop = '68px'
						element.scrollIntoView();
					} else {
						document.documentElement.scrollTop = 0;
					}
				})
			},

			headerBtnClick(e) {
				console.log('headerBtnClick')
			},
			headerMenuClick(e) {
				console.log('headerMenuClick:' + e)
				this.scrollToEL(e)
			},
			onMouseenter(resource) {
				resource.active = true
				this.resourceWrapStyle(true)
			},
			onMouseleave(resource) {
				resource.active = false
				this.resourceWrapStyle(false)
			},
			resourceWrapStyle(enter) {
				let count = this.resources.length;
				if (enter) {
					const el = this.$refs.resourcesWrap
					if (!el) return
					let rect = el.getBoundingClientRect();
					let rectWidth = rect.width * window.devicePixelRatio
					let average = (rectWidth - (count - 1) * 1) / count
					let big = average * 1.72
					let small = (rectWidth - big) / (count - 1)

					let gridC = ''
					this.resources.forEach((mech, index) => {
						if (mech.active == true) {
							if (index == 0) gridC += (big + 'px')
							else gridC += (' ' + big + 'px')
						} else {
							if (index == 0) gridC += (small + 'px')
							else gridC += (' ' + small + 'px')
						}
					})
					this.resourcesStyle = {
						gridTemplateColumns: gridC
					}
				} else {
					this.resourcesStyle = {
						gridTemplateColumns: 'repeat(' + count + ', 1fr)'
					}
				}
			},

			onDownload() {

			},
			onVideo() {

			},
			handlePage(page) {
				let that = this
				console.log('page:' + JSON.stringify(page))
				let header = page.header
				if (header != null) {
					if (header.bgUrl) {
						header.bgUrl = that.__imagePath + header.bgUrl
					}
					that.header = header;
				}

				let archBgImg = page.architectureImg;
				if (archBgImg) {
					that.architectureImg = that.__imagePath + archBgImg
				}
				let scenes = page.scenes;
				if (scenes && scenes.length > 0) {
					that.scenes = scenes
				}
				let functions = page.functions;
				if (functions && functions.length > 0) {
					that.functions = functions
				}
				let resources = page.resources;
				if (resources && resources.length > 0) {
					that.resources = resources
					this.resourcesStyle = {
						gridTemplateColumns: 'repeat(' + resources.length + ', 1fr)'
					}
				}
			},
			loadDatas() {
				this.handlePage(localPage);
				let that = this;
				aEntityAPI.request("HomePageConfigService", "getYjySolution", {
					solutionType: 87
				}).then((response) => {
					if (response.data.success) {
						let pages = response.data.data.solution;
						if (pages != null && pages.contantDsc) {
							that.handlePage(JSON.parse(pages.contantDsc));
						}

					}
				});
			}
		},
	}
</script>


<style lang="scss" scoped>
	ul {
		list-style: none;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.el-carousel__item {
		display: flex;
		flex-direction: row;
		// grid-template-columns: repeat(2 , 50%);
		// grid-gap: 0 24px;
	}

	.solution_body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;
		background-color: transparent;
	}

	.dray_background {
		background: #f4f8fb;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
			text-align: center;
		}
	}

	/*  header  */
	.solu_header {
		display: flex;
		flex-direction: column;
	}

	/* 应用场景 */
	.solu_scene {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.scene_wrap {

			margin-top: 50px;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 0 24px;

			.scene {

				height: 100%;

				display: flex;
				flex-direction: column;

				border-radius: 14px;
				background-color: #FFFFFF;
				overflow: hidden;

				img {
					width: 100%;
					aspect-ratio: 3.766;
					object-fit: contain;

					background-color: rgba(0, 0, 0, 0.2);
				}

				.scene_title {
					flex: 1;

					padding: 4.2%;

					.p1 {
						font-family: HanSans-Medium;
						font-weight: 500;
						font-size: 18px;
						color: #353C58;
					}

					ul {
						margin-top: 2.8%;

						li {
							p {
								font-family: Source Han Sans, Source Han Sans;
								font-weight: 400;
								font-size: 14px;
								color: #788296;
							}
						}
					}
				}

			}

			.scene:hover {
				box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
			}
		}
	}

	/* 架构图 */
	.solu_architecture {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;

		.architecture_wrap {

			margin-top: 50px;

			img {
				width: 100%;
			}
		}
	}

	/* 核心功能 */
	.solu_functions {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;

		.functions_wrap {
			margin-top: 60px;

			flex: 1;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 38px 28px;

			.function_wrap {
				background-color: #FFFFFF;
				border-radius: 10px;

				padding: 24px 50px;

				display: flex;
				flex-direction: column;

				.p1 {
					font-family: HanSans-Medium;
					font-weight: 500;
					font-size: 20px;
					color: #333333;

					margin-bottom: 10px;
				}

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
				}
			}

			.function_wrap:hover {
				box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.1);
			}

		}
	}

	/* 资源丰富 */
	.solu_resources {
		display: flex;
		flex-direction: column;

		background-color: #FFFFFF;
		padding: 70px 0;
		padding: 4.375rem 0;


		.resources_wrap {
			margin-top: 65px;
			margin-top: 3rem;

			aspect-ratio: 3.81;

			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-gap: 0 1px;

			transition: all 0.25s ease;

			.resource_item {

				height: 100%;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				border: 1px solid #E5E7EB;

				transition: all 0.25s ease;

				.resource_item_wrap {
					flex: 1;
					width: 100%;

					display: flex;
					flex-direction: column;
					align-items: center;

					.p_title {
						margin-top: 20px;

						p {
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 18px;
							color: #353C58;
						}
					}

					.p_active {
						margin-top: 30px;

						p {
							font-family: HanSans-Medium;
							font-weight: 500;
						}
					}

					img {
						width: 34px;
						height: 34px;

						object-fit: contain;
					}

					.img_active {
						width: 66px;
						height: 66px;
					}

					ul {
						margin-top: 15px;
					}

					li {
						flex: 1;
						padding: 0 30px;

						p {

							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #788296;
							text-align: center;
						}
					}
				}
			}
		}
	}


	.solu_download {
		display: flex;
		flex-direction: column;

		padding: 70px 0;
		padding: 4.375rem 0;
	}
</style>