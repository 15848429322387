<template>
	<div class="sc_page">
		<div class="sc_header body_suit">
			<commonHeaderPage :model="dataDto.header" @btnClick="titleClickAction" />
		</div>
		<div class="sc_case body_suit">

			<div class="item_wrap" v-for="(item, index) in dataDto.solutionList" :key="index">
				<div class="item_header">
					<p>{{ item.title }}</p>
				</div>

				<div class="scc_cell_body">
					<div class="cell_item" v-for="(cell, cellIndex) in item.items" :key="cellIndex">
						<img :src="cell.icon"></img>
						<p class="cell_item_title">{{ cell.title }}</p>
						<p class="cell_item_sub">{{ cell.desc }}</p>
						<div class="cell_item_btn" @click="caseItemAction(cell)">
							<p>{{ cell.btnName }}</p>
						</div>
					</div>
				</div>

				<!-- <div class="scc_cell" v-for="(item, index) in dataDto.solutionList" :key="index">
					<div class="scc_cell_title">{{ item.title }}</div>
					
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import aEntityAPI from "@/api/sysAPI.js";
	import commonHeaderPage from "./solution/commonView/commonHeaderPage.vue";

	export default {
		components: {
			commonHeaderPage
		},
		data() {
			return {


				dataDto: {
					header: {
						title: '全部解决方案',
						subTitle: '提供多种解决方案，满足您多场景需求',
						btnName: '专家咨询',
						bgUrl: require("@/assets/yjysite/case/sc_title_bg.png")
					},
					solutionList: [{
							title: "运输解决方案",
							items: [{
									title: "智能运输解决方案",
									icon: require("@/assets/yjysite/case/ys_1.png"),
									btnName: "了解详情",
									route: "znys",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "网络货运解决方案",
									icon: require("@/assets/yjysite/case/ys_2.png"),
									btnName: "了解详情",
									route: "wlhy",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "数字水运解决方案",
									icon: require("@/assets/yjysite/case/ys_3.png"),
									btnName: "了解详情",
									route: "szsy",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "多式联运解决方案",
									icon: require("@/assets/yjysite/case/ys_4.png"),
									btnName: "了解详情",
									route: "dsly",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
							]
						},
						{
							title: "仓储解决方案",
							items: [{
									title: "智能仓储解决方案",
									icon: require("@/assets/yjysite/case/cs_1.png"),
									btnName: "了解详情",
									route: "zncc",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},

							]
						},
						{
							title: "园区解决方案",
							items: [{
									title: "智能物流园区解决方案",
									icon: require("@/assets/yjysite/case/yq_1.png"),
									btnName: "了解详情",
									route: "znwlyq",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},

							]
						},
						{
							title: "人力解决方案",
							items: [{
									title: "众包产品介绍-灵工解决方案",
									icon: require("@/assets/yjysite/case/rl_1.png"),
									btnName: "了解详情",
									route: "lhyg",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{	
									title: "汇用工解决方案",
									icon: require("@/assets/yjysite/case/rl_2.png"),
									btnName: "了解详情",
									route: "hyg",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								}
								// ,
								// {
								// 	title: "全用工系统",
								// 	icon: require("@/assets/yjysite/case/rl_3.png"),
								// 	btnName: "了解详情",
								// 	route: "",
								// 	desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								// },

							]
						},
						{
							title: "SaaS解决方案",
							items: [{
									title: "物流SaaS",
									icon: require("@/assets/yjysite/case/sa_1.png"),
									btnName: "了解详情",
									route: "wlsaas",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "人力SaaS",
									icon: require("@/assets/yjysite/case/sa_2.png"),
									btnName: "了解详情",
									route: "saashr",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},

							]
						},
						{
							title: "平台解决方案",
							items: [{
									title: "城市物流资源整合平台",
									icon: require("@/assets/yjysite/case/pt_1.png"),
									btnName: "了解详情",
									route: "cswlpt",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "物流与供应链大数据平台",
									icon: require("@/assets/yjysite/case/pt_2.png"),
									btnName: "了解详情",
									route: "dsjpt",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "县域综合物流平台",
									icon: require("@/assets/yjysite/case/pt_3.png"),
									btnName: "了解详情",
									route: "",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "细分行业供应链服务平台",
									icon: require("@/assets/yjysite/case/pt_4.png"),
									btnName: "了解详情",
									route: "gylpt",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "共享IT平台",
									icon: require("@/assets/yjysite/case/pt_5.png"),
									btnName: "了解详情",
									route: "gxitb",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								}
							]
						},
						{
							title: "其他解决方案",
							items: [{
									title: "供应链控制塔",
									icon: require("@/assets/yjysite/case/qt_1.png"),
									btnName: "了解详情",
									route: "gylkzt",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								},
								{
									title: "物流招投标解决方案",
									icon: require("@/assets/yjysite/case/qt_2.png"),
									btnName: "了解详情",
									route: "ztb",
									desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								}
								// ,
								// {
								// 	title: "供应链金融解决方案",
								// 	icon: require("@/assets/yjysite/case/qt_3.png"),
								// 	btnName: "了解详情",
								// 	route: "",
								// 	desc: "为物流需求方提供承运商管理、运费结算、运费协同与透明化系统支撑；20年运营经验和管理沉淀，数字化经营与生态协同完美结合"
								// },


							]
						}
					]
				},

				companyInfo: {
					mobile: "400-111-5856",
					email: "jinjun@gsh56.com"
				}, //公司信息
			};
		},

		created() {

		},

		mounted() {
			let that = this;
		},

		methods: {
			//点击图标的立即进入按钮
			titleClickAction() {

			},

			caseItemAction(item) {
				let fillName = item.route + 'Solution'
				let path = 'solution/' + fillName

				var url = window.location.protocol + "//" + window.location.host + "/yjysite.html#/" + path;
				console.log('showProductDetailAction:' + url);
				window.location.href = url;
				document.documentElement.scrollTop = 0;	
			},
		},
	};
</script>


<style lang="scss" scoped>
	.sc_page {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;

		background-color: #F4F7FA;
	}

	.body_suit {
		width: 100%;
		max-width: 1920px;
		min-width: 1366px;
	}

	.item {
		flex: 1;
	}

	.item_wrap {
		display: flex;
		flex-direction: column;

		padding: 0 12.5%;
		z-index: 21;

		// background-color: cornsilk;
	}

	.item_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		// align-items: center;

		p {
			font-family: HanSans-Blod;
			font-weight: 700;
			font-size: 28px;
			color: #191919;
		}

		.p_sub {
			margin-top: 15px;

			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
		}
	}


	.sc_header {
		display: flex;
		flex-direction: column;
	}

	.sc_case {

		padding: 5% 0 5% 0;

		display: grid;
		grid-template-columns: 100%;
		grid-gap: 65px 0;

		.scc_cell_body {
			margin-top: 1.7%;

			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 21px;
		}

		.cell_item:hover {
			box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
		}

		.cell_item {

			// 466px

			display: flex;
			flex-direction: column;
			align-items: flex-start;

			padding: 8.5% 10%;

			background: #FFFFFF;
			border-radius: 14px;

			img {
				width: 15%;
				aspect-ratio: 1;
			}

			.cell_item_title {

				font-family: HanSans-Blod;
				font-weight: 700;
				font-size: 20px;
				color: #3D4461;

				margin-top: 6.8%;
			}

			.cell_item_sub {
				font-family: Source Han Sans, Source Han Sans;
				font-weight: 400;
				font-size: 16px;
				color: #64698B;
				line-height: 24px;

				margin-top: 2.1%;
			}

			.cell_item_btn {

				margin-top: 5.5%;
				padding: 0 6%;
				height: 38px;

				border-radius: 19px;
				border: 1px solid #64698B;

				display: flex;
				align-items: center;

				p {
					font-family: Source Han Sans, Source Han Sans;
					font-weight: 400;
					font-size: 14px;
					color: #3D4461;
					text-align: center;
				}
			}

			.cell_item_btn:hover {
				border: 1px solid rgba(100, 105, 139, 0.5);
				cursor: pointer;
			}
		}
	}
</style>